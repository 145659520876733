import { useForm } from '@conform-to/react';
import { parse } from '@conform-to/zod';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type DataFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  Form,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useFetchers,
  useLoaderData,
  useSubmit } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
//import { GiftIcon } from 'lucide-react'
import { useRef } from 'react';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuPortal } from
'#components/ui/dropdown-menu.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { ErrorList } from './components/forms.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { EpicToaster } from './components/toaster.tsx';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from './components/ui/alert-dialog.tsx';
import { Button } from './components/ui/button.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import { Separator } from './components/ui/separator.tsx';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId } from './utils/auth.server.ts';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { prisma } from './utils/db.server.ts';
import { getEnv, getTarrakkiEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl, getUserImgSrc } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { userHasRole } from './utils/permissions.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { type Theme, getTheme, setThemeLightOrDark } from './utils/theme.server.ts';
import { makeTimings } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useOptionalUser } from './utils/user.ts';

const DEFAULT_THEME = 'dark';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'Super FD' : 'Error | Super FD' },
  { name: 'description', content: `Earn 9.85% interest rate on your next FD` }];

};

export async function loader({ request }: DataFunctionArgs) {

  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  const timings = makeTimings('root loader');

  const userId = await getUserId(request);
  const user = userId ?
  await prisma.user.findUniqueOrThrow({
    select: {
      id: true,
      name: true,
      age: true,
      isOnBoard: true,
      image: {
        select: {
          id: true
        }
      },
      roles: {
        select: {
          name: true,
          permissions: {
            select: { entity: true, action: true, access: true }
          }
        }
      }
    },
    where: { id: userId }
  }) :
  null;

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request) ?? DEFAULT_THEME
        }
      },
      ENV: getEnv(),
      tarrakkiENV: getTarrakkiEnv(),
      toast,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

// const ThemeFormSchema = z.object({
// 	theme: z.enum(['system', 'light', 'dark']),
// })

const ThemeFormSchemaLightOrDark = z.object({
  theme: z.enum(['light', 'dark'])
});

export async function action({ request }: DataFunctionArgs) {
  const formData = await request.formData();
  const submission = parse(formData, {
    schema: ThemeFormSchemaLightOrDark
  });
  if (submission.intent !== 'submit') {
    return json(({ status: 'idle', submission } as const));
  }
  if (!submission.value) {
    return json(({ status: 'error', submission } as const), { status: 400 });
  }
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setThemeLightOrDark(theme) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  nonce,
  theme = DEFAULT_THEME,
  env = {},
  tarrakkiEnv






}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;tarrakkiEnv?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />

				<script nonce={nonce} src={tarrakkiEnv ? tarrakkiEnv.TARRAKKI_SDK_URL : ''} />

				<script nonce={nonce} async src={tarrakkiEnv ? `https://www.googletagmanager.com/gtag/js?id=${tarrakkiEnv.GOOGLE_TAG_ID}` : ''} />

				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', '${tarrakkiEnv ? tarrakkiEnv.GOOGLE_TAG_ID : ''}', {
						page_path: window.location.pathname
					});
					`
          }} />

				{/* <script
          type="text/javascript"
          src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
          id="aisensy-wa-widget"
          widget-id="qJa6bV"
          >
          </script>
          */}

				{/* <script 
             nonce={nonce}
             dangerouslySetInnerHTML={{
          	__html: `
          	var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?90000';
          	var s = document.createElement('script');
          	s.type = 'text/javascript';
          	s.async = true;
          	s.src = url;
          	var options = {
          	"enabled":true,
          	"chatButtonSetting":{
          		"backgroundColor":"#00E785",
          		"ctaText":"Chat with Super FD",
          		"borderRadius":"25",
          		"marginLeft": "0",
          		"marginRight": "20",
          		"marginBottom": "20",
          		"ctaIconWATI":false,
          		"position":"right"
          	},
          	"brandSetting":{
          		"brandName":"Super FD",
          		"brandSubTitle":"undefined",
          		"brandImg":"https://www.superfd.in/",
          		"welcomeText":"Hi there!\n\nHow can we help you?",
          		"messageText":"%0A",
          		"backgroundColor":"#00E785",
          		"ctaText":"Chat with Super FD",
          		"borderRadius":"25",
          		"autoShow":true,
          		"phoneNumber":"919902872525"
          	}
          	};
          	s.onload = function() {
          		console.log('creating whasapp chat widget')
          		CreateWhatsappChatWidget(options);
          	};
          	var x = document.getElementsByTagName('script')[0];
          	x.parentNode.insertBefore(s, x);
          	`,
          }}
          /> */}
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const user = useOptionalUser();
  const theme = useThemeLightOrDark();
  const userIsAdmin = userHasRole(user, 'admin');

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV} tarrakkiEnv={data.tarrakkiENV}>
			<div className="flex h-screen flex-col justify-between">
				<header className="container p-4 sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
					<nav>
						<div className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
							<Link to="/">
								<div className="font-bold text-2xl bg-[linear-gradient(to_right,theme(colors.indigo.600),theme(colors.indigo.700),theme(colors.sky.600),theme(colors.fuchsia.600))] dark:bg-[linear-gradient(to_right,theme(colors.indigo.400),theme(colors.indigo.100),theme(colors.sky.400),theme(colors.fuchsia.400))] bg-clip-text bg-[length:100%_auto] dark:bg-[length:200%_auto] text-transparent">Super FD</div>
							</Link>
							
							<div className="flex items-center gap-10">
							{user ?
                <div className="flex items-center gap-2">
								{/*<Button asChild variant="secondary">
                    <Link to="/refer_and_earn">
                                                  <GiftIcon className="h-4 w-4 text-gain" />
                    	<span className=" pl-1 hidden sm:block">Refer</span>
                    </Link>
                    </Button>
                    */}
								{userIsAdmin ?
                  <Button asChild variant="secondary">
										<Link to="/admin">
											<Icon name="backpack">
												<span className="hidden sm:block">Admin</span>
											</Icon>
										</Link>
									</Button> :
                  null}
								{/* <Button asChild variant="secondary">
                    <Link
                    	to={`/users/${user.name}`}
                    	className="flex items-center gap-2"
                    >
                    	<img
                    		className="h-8 w-8 rounded-full object-cover"
                    		alt={user.name}
                    		src={getUserImgSrc(user.image?.id)}
                    	/>
                    	<span className="hidden text-body-sm font-bold sm:block">
                    		{user.name}
                    	</span>
                    </Link>
                    </Button> */}
								<UserDropdown />
								
							</div> :

                <Button asChild variant="default" size="sm">
								<Link to="/auth">Log In</Link>
							</Button>}

							</div>
						</div>
					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>

				

				<footer className="py-10">
				<div className="container mx-auto px-4 sm:px-6 lg:px-8">
				    <Separator className="my-10" />
					<div className="flex flex-col md:flex-row justify-between">
					<div className="flex lg:flex-col justify-between">
						<div className=" mb-10">
							<Link className="block mb-4 text-xl font-semibold" to="/">
							Super FD
							</Link>
							{/* <div className="flex space-x-4">
                    <TwitterIcon className="text-gray-500 dark:text-gray-400" />
                    <GithubIcon className="text-gray-500 dark:text-gray-400" />
                    <SlackIcon className="text-gray-500 dark:text-gray-400"/>
                    <YoutubeIcon className="text-gray-500 dark:text-gray-400" />
                    </div> */}
						</div>
						<div>
							<ThemeSwitchLightOrDark userPreference={data.requestInfo.userPrefs.theme} />
						</div>
					</div>

					<div className="flex flex-col items-start gap-8 md:flex-row md:gap-30">
					<div className="flex flex-col items-start gap-4">
						<Link className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50" to="/about">
						About Us
						</Link>
						<Link className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50" to="/support">
						Support
						</Link>
					
					</div>
					<div className="flex flex-col items-start gap-2">
						<div className="text-gray-500 dark:text-gray-400">Super FD,</div>
						<div className="text-gray-500 dark:text-gray-400">Wework Cherry Hills,</div>
						<div className="text-gray-500 dark:text-gray-400">Embassy Golf Links BP,</div>
						<div className="text-gray-500 dark:text-gray-400">Domlur,</div>
						<div className="text-gray-500 dark:text-gray-400">Bangalore,</div>
						<div className="text-gray-500 dark:text-gray-400">Karnataka - 560071</div>
					</div>
					</div>
					
					{/* <div className="w:5/5 md:w-3/5 flex justify-between">
                
                <div>
                <h3 className="font-semibold mb-3">Company</h3>
                <ul>
                	<li>
                	<Link className="block mb-2 " to="#">
                		About us
                	</Link>
                	</li>
                	<li>
                	<Link className="block mb-2 " to="#">
                		Blog
                	</Link>
                	</li>
                				</ul>
                </div>
                <div>
                <h3 className="font-semibold mb-3">Reach out to us</h3>
                <p>
                	<p>Wework Cherry Hills,</p>
                	<p>Embassy Golf Links BP,</p>
                	<p>Domlur</p>
                	</p>
                
                </div>
                
                </div> */}



					</div>
				</div>
				</footer>

				{/* <div className="container flex justify-between pb-5">
          <div>
          	<Link to="/">
          		<div className="font-light">Home</div>
          	</Link>
          	<Link to="/about">
          		<div className="font-light">About</div>
          	</Link>
          </div>
          					<ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
          </div> */}

			</div>
			<EpicToaster toast={data.toast} />
			<EpicProgress />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

function UserDropdown() {
  const user = useOptionalUser();
  const submit = useSubmit();
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="secondary" asChild>
					<Link
            to={`/`}
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2">

						<img
              className="h-8 w-8 rounded-full object-cover"
              alt={user?.name}
              src={getUserImgSrc(user?.image?.id)} />

						<span className="text-body-sm font-bold">
							{user?.name}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user?.id}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/bookedfd`}>
							<Icon className="text-body-md" name="id-card">
								Booked FD
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
            asChild
            // this prevents the menu from closing before the form submission is completed
            onSelect={(event) => {
              event.preventDefault();
              submit(formRef.current);
            }}>

						{/* <Form action="/logout" method="POST" ref={formRef}>
              <Icon className="text-body-md" name="exit">
              	<button type="submit">Logout</button>
              </Icon>
              </Form> */}
						<AlertDialog
              aria-label="Confirm Logout">

                        <AlertDialogTrigger className='w-full'>

                          <Button variant={"outline"} className="w-full">
						    <Icon className="text-body-md" name="exit">
								Logout
							</Icon>
						  </Button>
						  
                        </AlertDialogTrigger>
                  			<AlertDialogContent>
                  				<AlertDialogHeader>
                  					<AlertDialogTitle>Are you still want to logout?</AlertDialogTitle>
                  				</AlertDialogHeader>
                  				<AlertDialogDescription>
                  					Once Confirmed, You are going to be redirected to Home page.
                  				</AlertDialogDescription>
                  				<AlertDialogFooter className="flex items-end gap-8">
                  					<AlertDialogCancel>
                  						Cancel
                  					</AlertDialogCancel>
                  					<Form action="/logout" method="POST" ref={formRef}>
                  						<AlertDialogAction type="submit">Confirm Logout</AlertDialogAction>
                  					</Form>
                  				</AlertDialogFooter>
                  			</AlertDialogContent>
                  		</AlertDialog>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>);

}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
// export function useTheme() {
// 	const hints = useHints()
// 	const requestInfo = useRequestInfo()
// 	const optimisticMode = useOptimisticThemeMode()
// 	if (optimisticMode) {
// 		return optimisticMode === 'system' ? hints.theme : optimisticMode
// 	}
// 	// for Auto pickup color preference theme 
// 	return requestInfo.userPrefs.theme ?? hints.theme
// }

export function useThemeLightOrDark() {
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeModeLightOrDark();
  if (optimisticMode) {
    return optimisticMode;
  }
  // for Auto pickup color preference theme 
  return requestInfo.userPrefs.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
// export function useOptimisticThemeMode() {
// 	const fetchers = useFetchers()
// 	const themeFetcher = fetchers.find(f => f.formAction === '/')

// 	if (themeFetcher && themeFetcher.formData) {
// 		const submission = parse(themeFetcher.formData, {
// 			schema: ThemeFormSchema,
// 		})
// 		return submission.value?.theme
// 	}
// }

export function useOptimisticThemeModeLightOrDark() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parse(themeFetcher.formData, {
      schema: ThemeFormSchemaLightOrDark
    });
    return submission.value?.theme;
  }
}

// function ThemeSwitch({ userPreference }: { userPreference?: Theme | null }) {
// 	const fetcher = useFetcher<typeof action>()

// 	const [form] = useForm({
// 		id: 'theme-switch',
// 		lastSubmission: fetcher.data?.submission,
// 	})

// 	const optimisticMode = useOptimisticThemeMode()
// 	const mode = optimisticMode ?? userPreference ?? 'system'
// 	const nextMode =
// 		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'
// 	const modeLabel = {
// 		light: (
// 			<Icon name="sun">
// 				<span className="sr-only">Light</span>
// 			</Icon>
// 		),
// 		dark: (
// 			<Icon name="moon">
// 				<span className="sr-only">Dark</span>
// 			</Icon>
// 		),
// 		system: (
// 			<Icon name="laptop">
// 				<span className="sr-only">System</span>
// 			</Icon>
// 		),
// 	}

// 	return (
// 		<fetcher.Form method="POST" {...form.props}>
// 			<input type="hidden" name="theme" value={nextMode} />
// 			<div className="flex gap-2">
// 				<button
// 					type="submit"
// 					className="flex h-8 w-8 cursor-pointer items-center justify-center"
// 				>
// 					{modeLabel[mode]}
// 				</button>
// 			</div>
// 			<ErrorList errors={form.errors} id={form.errorId} />
// 		</fetcher.Form>
// 	)
// }

function ThemeSwitchLightOrDark({ userPreference }: {userPreference?: Theme | null;}) {
  const fetcher = useFetcher<typeof action>();

  const [form] = useForm({
    id: 'theme-switch-light-dark',
    lastSubmission: fetcher.data?.submission
  });

  const optimisticMode = useOptimisticThemeModeLightOrDark();
  const mode = optimisticMode ?? userPreference ?? 'dark';
  const nextMode =
  mode === 'dark' ? 'light' : 'dark';
  const modeLabel = {
    light:
    <Icon name="sun">
				<span className="sr-only">Light</span>
			</Icon>,

    dark:
    <Icon name="moon">
				<span className="sr-only">Dark</span>
			</Icon>

  };

  return (
    <fetcher.Form method="POST" {...form.props}>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
          type="submit"
          className="flex h-8 w-8 cursor-pointer items-center justify-center">

					{modeLabel[mode]}
				</button>
			</div>
			<ErrorList errors={form.errors} id={form.errorId} />
		</fetcher.Form>);

}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}